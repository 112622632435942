.modal {
    width: 100%;
    height: 101%;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgb(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 4;
    transition: all 1s ease;

    &-container {
        display: flex;
        flex-direction: column;
        width: 500px;
        height: 95vh;
        background-color: white;
        border-radius: 10px;
        @media(max-width: 650px) {
            width: 350px;
        }

        &Icon {
            align-self: flex-end;
        }

        &Img {
            height: 100%;
            overflow: hidden;
        }
    }

    &-icon {
        width: 30px;
        height: 30px;
        margin: 5px;
        cursor: pointer;
    }

    &-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}