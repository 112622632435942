.consulta {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgb(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 6;

    &-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        padding: 2rem;
        @media(max-width: 650px) {
            width: 400px;
            height: 550px;
            padding: 1rem;
        }
        @media(max-width: 450px) {
            width: 300px;
            height: 450px;
        }
    }

    &-img {
        width: 200px;
        height: 200px;
        margin: 1rem;
        @media(max-width: 450px) {
            width: 125px;
            height: 125px;
            margin: 0 0 1rem 0;
        }
    }

    &-title {
        font-family: "Copperplate Gothic Bold";
        font-size: 1.3rem;
        text-align: center;
        font-weight: bold;
        @media(max-width: 450px) {
            font-size: 1.1rem;
        }
    }
    
    &-boxText {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 250px;
        text-align: center;
        @media(max-width: 450px) {
            width: none;
        }
    }

    &-text {
        font-size: 3rem;  
        font-family: 'Roboto', sans-serif;
        @media(max-width: 768px) {
            font-size: 2.5rem;
        }
        @media(max-width: 650px) {
            font-size: 2rem;
        }
        @media(max-width: 450px) {
            font-size: 1.5rem; 
        }
    }

    &-desc {
        font-family: 'Roboto', sans-serif;
    }
    
    &-warning {
        font-family: 'Roboto', sans-serif;
        color: red;
        height: 20px;
        @media(max-width: 450px) {
            height: fit-content; 
        }
    }

    &-boxBtns {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
    }

    &-advice {
        font-family: 'Playfair Display', serif;
        font-style: italic;
        text-align: center;
    }
}