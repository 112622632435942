.card {
    width: 350px;
    height: 575px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: white;
    margin: 5px;
    position: relative;
    transition: all .5s;
    @media(max-width: 992px) {
        width: 300px;
        height: 525px;
    }

    &:hover {
        transition: all .5s;
        background-color: rgb(0, 0, 0, 0.3);
    }

    &-off {
        position: absolute;
        top: 5px;
        right: 5px;
        display: grid;
        place-items: center;
        background-color: #A60000;
        border-radius: 50%;
        font-family: 'Roboto', sans-serif;
        color: white;
        padding: .5rem;
    }

    &-imageContent {
        height: 65%;
    }

    &-img {
        height: 100%;
    }

    &-text {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 35%;
        width: 100%;
        color: black;
    }

    &-title {
        max-width: 300px;
        font-family: 'Roboto', sans-serif;
        font-size: 1.3rem;
        text-align: center;
    }

    &-desc {
        font-family: 'Roboto', sans-serif;
        font-weight: 100;
        text-align: center;
        font-weight: bold;
    }

    &-price {
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
    }
        
    &-btn {
        width: 100px;
        height: 30px;
        border: none;
        border-radius: 5px;
        background-color: #D4AF37;
        color: white;
        text-transform: uppercase;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        z-index: 0;
        text-decoration: none;
        cursor: pointer;
    }

}

.tachado {
    text-decoration: line-through;
}
// .card {
//     width: 250px;
//     height: 312px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     overflow: hidden;
//     border: 4px #394749 solid;
//     border-radius: 10px;
//     background-color: rgb(203, 202, 181);
//     margin: 5px;

//     &__image {
//         width: 100%;
//     }

//     &__text {
//         text-align: center;
//         position: absolute;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         width: 250px;
//         height: 312px;
//         border-radius: 10px;
//         transition: all .3s;
//         transform: scale(1);
//         opacity: 0;
//         background-color: rgb(0, 0, 0, 0.3);
        
//         &:hover {
//             transform: scale(1);
//             opacity: 1;
        
//         }
//     }

//     &__title {
//         font-family: 'Roboto', sans-serif;
//         font-size: 1.4rem;
//         font-weight: bold;
//         margin-bottom: 10px;
//         color: black;
//         text-shadow: -2px -2px 2px darkgrey;
//     }
    
//     &__price {
//         font-family: 'Roboto', sans-serif;
//         font-size: 1rem;
//         font-weight: bold;
//         color: black;
//         padding: 10px 20px;
//         text-shadow: -2px -2px 2px darkgrey;
//     }

//     &__link {
//         z-index: 0;
//     }

//     &__btn {
//         width: 100px;
//         height: 30px;
//         margin-bottom: 25px;
//         border: 2px black solid;
//         border-radius: 5px;
//         background-color: royalblue;
//         color: white;
//         text-transform: uppercase;
//         font-weight: 700;
//         z-index: 8;
//         cursor: pointer;
//     }
    
// }