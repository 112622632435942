.detalle-venta {
    display: flex;
    margin: 10px;
    @media(max-width: 992px) {
        flex-direction: column-reverse;
        align-items: center;
    }
}

.detalle-ventaBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;
    overflow: hidden;
    margin: 10px;
    @media(max-width: 992px) {
        width: 85%;
    }
}

.detalle-ventaImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media(max-width: 992px) {
        width: 75%;
        height: 75%;
    }
}

.detalle-ventaTitle {
    font-family: "Roboto", sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    @media(max-width: 768px) {
        font-size: 2rem;
    }
    @media(max-width: 650px) {
        font-size: 1.6rem;
    }
}

.detalle-ventaDesc {
    font-family: "Playfair Display", serif;
    font-size: 1.3rem;
    font-style: italic;
    letter-spacing: 0.5px;
    line-height: 2rem;
    @media(max-width: 992px) {
        margin: 1rem;
    }
    @media(max-width: 768px) {
        width: 100%;
        font-size: 1rem;
    }
}

.detalle-ventaBtn {
    margin: 4rem 2rem;
}