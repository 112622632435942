.content-producto {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // @media(max-width: 1200px) {
    //     flex-direction: column-reverse;
    //     align-items: center;
    // }
    @media(max-width: 1200px) {
        justify-content: space-evenly;
    }
    @media(max-width: 992px) {
        flex-direction: column-reverse;
        align-items: center;
    }
}

.content-img {
    position: relative;
    width: 45%;
    min-width: 576px;
    height: 580px;
    background-color: white;
    transition: all 0.5s;
    @media(max-width: 1200px) {
        width: 40%;
        min-width: 400px;
    }
    @media(max-width: 992px) {
        width: 60%;
        min-width: 400px;
        margin: 1rem;
    }
    @media(max-width: 768px) {
        height: 500px;
    }
    @media(max-width: 650px) {
        width: 100%;
        height: 400px;
        min-width: 200px;
        margin: 1rem 0 0 0;
    }

    &:hover {
        transition: all 0.5s;
        background-color: rgba(0, 0, 0, 0.3);   
    }
}

.content-off {
    position: absolute;
    top: 5px;
    right: 5px;
    display: grid;
    place-items: center;
    background-color: #A60000;
    border-radius: 50%;
    font-family: 'Roboto', sans-serif;
    color: white;
    padding: .5rem;
}

.content-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.content-detail {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-evenly;
    @media(max-width: 1200px) {
        width: 40%;
    }
    @media(max-width: 992px) {
        width: 60%;
    }
    @media(max-width: 650px) {
        width: 100%;
    }
}

.content-text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 70%;
}

.content-title {
    font-family: 'Playfair Display', serif;
    font-size: 4rem;
    margin-bottom: 10px;
    width: 425px;
    @media(max-width: 768px) {
        font-size: 3rem;
        width: auto;
    }
}

.content-desc {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    margin-bottom: 7px;
}

.desc-width {
    width: 425px;
    margin: 1rem;
    @media(max-width: 650px) {
        width: auto;
        margin: 0;
        padding: 1rem;
    }
}

.stock {
    color: #922c27;
    font-weight: bold;
}

.content-price {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 7px;
}

.content-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;
    justify-content: space-evenly;
}

.card-success {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b19129;
    text-decoration: none;
    font-size: 13px;
}

.card-size {
    width: 175px;
}
.link-detail {
    list-style-type: none;
    text-decoration: none;
    color: black;
}