.cargando {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgb(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 5;

    &-text {
        font-family: 'Playfair Display', serif;
        letter-spacing: 2px;
        color: white;
        font-size: 4rem;
        text-shadow: -2px 2px black;
        @media(max-width: 768px) {
            font-size: 2rem;
        }
    }

    &-img {
        width: 300px;
        height: 300px;
        margin: 30px;
        @media(max-width: 768px) {
            width: 150px;
            height: 150px;
            margin: 15px;
        }
    }

    &-spinner {
        border: 12px solid rgba(255, 255, 255, .5);
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border-left-color: #922c27;
        animation: spin 1s ease infinite;
        @media(max-width: 768px) {
            width: 75px;
            height: 75px;
        }
    }
    
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    
}

