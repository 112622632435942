.dropdown {
    margin: 1rem 0;
    
    &-header {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        background-color: #D4AF37;
        border-radius: 25px;
    }

    &-title {
        font-family: "Roboto", sans-serif;
        font-size: 1.3rem;
    }
    
    &-content {
        padding: 0;
        border-top: none;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-in-out;
        background-color: rgba(128, 128, 128, 0.545);
        border-radius: 25px;
        margin: 10px 0 0;
      }
      
      &-content.show {
        max-height: 800px; /* Ajusta la altura máxima según tus necesidades */
      }

      &-text {
        font-family: "Roboto", sans-serif;
        padding: 10px 20px;
      }
  }
  