.dest {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    &-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 1rem;
    }

    &-title {
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 2.5rem;
        width: 75%;
        @media(max-width: 768px) {
            font-size: 2rem;
        }
        @media(max-width: 650px) {
            font-size: 1.6rem;
        }
    }
}
