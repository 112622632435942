.flyer {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        font-size: 2.5rem;
        text-align: center;
        @media(max-width: 768px) {
            font-size: 2rem;
        }
        @media(max-width: 450px) {
            font-size: 1.6rem;
        }
    }

    &-contentImg {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        min-height: 375px;
        margin-top: 20px;
        padding: 10px;
        @media(max-width: 768px) {
            min-height: 485px;
        }
        @media(max-width: 484px) {
            min-height: 715px;
        }
    }

    &-boxImg {
        width: 250px;
        height: 250px;
        // background-color: rgba($color: #fffcfc, $alpha: .3);
        border-radius: 50%;
        overflow: hidden;
        transition: all .5s ease;
        z-index: 1;
        @media(max-width: 768px) {
            width: 200px;
            height: 200px;
        }
        @media(max-width: 768px) {
            margin: 1rem;
        }

        &:hover {
            background-color: rgba(194, 192, 192, 0.7);
            // box-shadow: 0 0 10px 2px black;
            transition: all .5s ease;
        }
        
    }

    &-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 1rem;
    }

    &-boxIcon {
        margin: 0 0 5px 0;
    }

    &-envio {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        height: 200px;
        background-color: white;
        @media(max-width: 1200px) {
            height: fit-content;
        }

        &Detail {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            width: 250px;
            text-align: center;
            text-decoration: none;
            color: black;
            margin: 1rem;
            @media(max-width: 1200px) {
                width: 200px;
            }
        }

        &Desc {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
        }

        &Icon {
            width: 60px;
            height: 60px;
            @media(max-width: 1200px) {
                width: 40px;
                height: 40px;
            }
        }
        &Text {
            width: 200px;
        }
    }
}

.icon-question {
    width: 12px;
    height: 12px;
    margin-left: 5px;
}

// .bandera {
//     position: absolute;
//     width: 100%;
//     height: inherit;

//     &-lineaRoja {
//         background-color: #c60b1e;
//         height: 20%;
//         width: 100%;
//     }
    
//     &-lineaAmarilla {
//         background-color: #ffc400;
//         height: 60%;
//         width: 100%;
//     }
// }