.notFound-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.notFound-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.notFound-title {
    font-family: 'Roboto', sans-serif;
    font-size: 3rem;
    font-weight: 700;
    text-shadow: -2px 2px 5px white;
    margin: 0 0 50px 0;
    text-align: center;
    @media(max-width: 768px) {
        font-size: 2rem;
    }
}

.notFound-desc {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    color: white;
    text-shadow: -2px 2px 5px black;
    margin: 0 0 50px 0;
    text-align: center;
    @media(max-width: 768px) {
        font-size: 1.1rem;
    }
}