.nosotros-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nosotros-contentBox {
    display: flex;
    align-items: center;
    margin: 1.5rem;
    @media(max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
}

.reverse {
    @media(max-width: 768px) {
        flex-direction: column-reverse;
    }
}

.nosotros-contentDesc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 1rem;
    @media(max-width: 768px) {
        width: 100%;
    }
}

.nosotros-contentImg {
    width: 50%;
    overflow: hidden;
    @media(max-width: 768px) {
        width: 75%;
    }
    @media(max-width: 650px) {
        width: 100%;
    }
}

.nosotros-imgWidth {
    width: 70%;
}

.nosotros-img {
    width: 100%;
}

.nosotros-title {
    font-family: "Roboto", sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    @media(max-width: 768px) {
        font-size: 2rem;
    }
}

.nosotros-desc {
    font-family: "Roboto", serif;
    // font-style: italic;
    font-size: 1.5rem;
    letter-spacing: 1px;
    line-height: 2rem;
    padding: 1rem;
    @media(max-width: 768px) {
        font-size: 1.2rem;
    }
}

.nosotros-width {
    width: 80%;
}

.video {
    height: 550px;
    object-fit: cover;
}