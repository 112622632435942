.content-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    //background-image: url("../../../public/img/pexels-lukas-1410141.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.content-character {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.content-datos {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 70%;
    margin: 1rem;
    @media(max-width: 992px) {
        flex-direction: column-reverse;
    }
    @media(max-width: 650px) {
        width: 100%;
    }
}

.content-datosCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin: 1rem;
    @media(max-width: 650px) {
        width: 100%;
    }
}

.content-premioBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media(max-width: 992px) {
        margin: 2rem;
    }
}

.content-premioTitle {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 1.3rem;
}

.content-premioRow {
    display: flex;
    justify-content: center;

    & hr {
        background-color: black;
        width: 5px;
        border: none;
    }
}

.content-premioDesc {
    margin: 10px;
    font-family: "Playfair Display", serif;
    font-size: 1.3rem;
    font-weight: bold;
    font-style: italic;
    letter-spacing: .5px;
    line-height: 2rem;
}

.content-map {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-characterTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    font-family: "Roboto", sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    text-shadow: -2px 2px 5px white;
    @media(max-width: 768px) {
        font-size: 2rem;
    }
}

.content-characterDesc {
    font-family: "Playfair Display", serif;
    font-size: 1.3rem;
    font-style: italic;
    letter-spacing: .5px;
    line-height: 2rem;
    margin: 1rem;
    text-align: center;
    text-shadow: -2px 2px 10px white;
    font-weight: bold;
    width: 50%;
    @media(max-width: 768px) {
        font-size: 1rem;
    }
    @media(max-width: 650px) {
        width: 75%;
    }
}

.map {
    border: none;
    width: 400px;
    height: 400px;
    @media(max-width: 450px) {
        width: 300px;
        height: 300px;
    }
}

.content-characterBox {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-ficha {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    @media(max-width: 1200px) {
        flex-direction: column;
    }
}

.content-fichaDesc {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    @media(max-width: 1200px) {
        width: 100%;
    }
}

.content-fichaTitle {
    font-family: "Roboto", sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    margin: 1rem;
    text-shadow: -2px 2px 5px white;
    @media(max-width: 768px) {
        font-size: 2rem;
    }
}

.content-fichaBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.content-fichaBoxCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 250px;
    margin: 1.5rem 2rem;
    @media(max-width: 1200px) {
        width: 400px;
    }
    @media(max-width: 768px) {
        width: 375px;
        margin: 1rem;
    }
    @media(max-width: 450px) {
        width: 300px;
    }
}

.content-fichaBoxTitle {
    font-family: "Roboto", sans-serif;
    font-size: 2rem;
    font-weight: bold;
    text-shadow: -2px 2px 5px white;
    @media(max-width: 768px) {
        font-size: 1.5rem;
    }
}

.content-fichaBoxDesc {
    font-family: "Playfair Display", serif;
    font-size: 1.3rem;
    font-style: italic;
    letter-spacing: .5px;
    line-height: 2rem;
    margin: 1rem;
    text-align: center;
    text-shadow: -2px 2px 5px white;
    font-weight: bold;
    @media(max-width: 768px) {
        font-size: 1.1rem;
    }
}

.content-cataBoxCol {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    align-items: center;
    justify-content: space-evenly;
    width: 450px;
    @media(max-width: 768px) {
        width: 400px;
    }
    @media(max-width: 450px) {
        width: 300px;
    }
}

.content-fichaIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px black solid;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    background-color: white;
    transition: all .2s ease;
    color: black;
    margin: 1rem;

    &:hover {
        background-color: #912c27;
        color: white;
        transition: all .2s ease;
        border-color: #912c27;
    }
}

.content-icon {
    width: 2rem;
    height: 2rem;
}
