.pay-box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-height: 250px;
    margin: 20px;
}
.pay-expand-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    height: 87vh;
}

.pay-title {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
}

.pay-desc {
    font-family: "Roboto", sans-serif;
    font-size: 1.3rem;
    text-align: center;
    margin: .5rem 0;
}

.pay-msj {
    background-color: #D4AF37;
    color: white;
    border-radius: 5px;
    padding: 5px;
    max-width: 750px;
}

.pay-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    width: 100%;
    padding: 1rem 0;
}

.pending-color {
    background: linear-gradient(0deg, rgba(255,255,255,0.7) 70%, rgba(201,144,74,1) 100%);
}
.failure-color {
    background: linear-gradient(0deg, rgba(255,255,255,0.7) 70%, rgb(249, 144, 135) 100%);
}

.success-color {
    background: linear-gradient(0deg, rgba(255,255,255,0.7) 70%, rgb(144, 231, 97) 100%);
}