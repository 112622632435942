.main-checkout {
    text-align: center;
    position: relative;
    //height: 100vh; //PARA OCUPAR EL LARGO DE LA PANTALLA. VER CUANDO ESTE EL FOOTER
}

.form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    
    &-checkout {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #dbcb92;
        border-radius: 10px;
        width: 600px;
        margin: 1rem;
        @media(max-width: 768px) {
            width: 500px;
        }
        @media(max-width: 650px) {
            width: 400px;
        }
    }

    &-asterisk {
        color: red;
        margin-top: 3rem
    }

    &-input {
        width: 250px;
        height: 45px;
        margin: 5px;
        padding-left: 5px;
        border: none;
        border-radius: 5px;
        outline: none;
        margin-bottom: 1rem;
    }

    &-input:focus {
        box-shadow: 0 0 0 0.25rem goldenrod;
    }

    &-title {
        font-family: "Roboto", sans-serif;
        margin-bottom: 1rem;
    }

    &-text {
        font-family: "Roboto", sans-serif;
    }

    &-btn {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        margin: 1rem;
    }

    &-text {
        color: black;
    }

    &-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1rem 1rem 0 1rem;
    }

    &-section-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &-section-col {
        display: flex;
        flex-direction: column;
    }

    &-info {
        display: flex;
        justify-content: center;
        margin-top: 2rem;     

        &Text{
            font-family: "Roboto", sans-serif;
            font-weight: 100;
            margin: 0 0 1rem;
        }

        &Icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 10%;
            background-color: #dbcb92;
            border-radius: 5px 0 0 5px;
            @media(max-width: 650px) {
                width: 15%;
            }
        }

        &Desc {
            display: flex;
            flex-direction: column;
            width: 70%;
            padding: 1rem;
            background-color: #dbcb92;
            border-radius: 0 5px 5px 0;
        }

    }
}

.form-icon {
    width: 50px;
    height: 50px;
    color: white;
}

.cart-width {
    margin: 10px 0;
}

.cart-detailItems {
    font-family: "Roboto", sans-serif;
    margin-bottom: 10px;
}

.checkout-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;

    &-int {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    &-msj {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 5px;
        height: 50px;
        margin-bottom: 20px;
        padding: 1rem;
        margin-top: 1rem;
        background-color: #D4AF37;
        color: white;
        @media(max-width: 650px) {
            height: 35px;
            width: min-content;
        }
    }
}

.checkout-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(161 161 161 / 75%);
    display: flex;
    justify-content: center;
    border-radius: 50px;
    opacity: 0;
    z-index: -1;
}

.checkout-expand {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    background-color: white;
    width: 75%;
    max-height: 890px;
    margin: 2rem 0;
    border-radius: 25px;
    overflow: hidden;
    @media(max-width: 650px) {
        width: 90%;
    }
}

.checkout-title {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 2rem;
}

.checkout-divisor {
    width: 100%;
    margin: 0 1rem 1rem;
}

.checkout-box {
    width: 100%;

    &Desc {
        font-style: italic;
        margin: .5rem;
    }
}

.checkout-desc {
    font-family: "Roboto", sans-serif;
    font-size: 1.3rem;
    text-align: center;
    @media(max-width: 650px) {
        font-size: 1rem;
    }
}

.checkout-pay {
    border: none;
    width: 100%;
    height: 100vh;
}

.showModal {
    opacity: 1;
    z-index: 0;
}
