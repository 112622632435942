.content-add {
    display: flex;
    align-items: center;
    border-radius: 5px;
    height: 25px;
    padding: 5px;
    position: relative;
    //bottom: 30px;
    background-color: #D4AF37;
    color: white;
    animation: mostrarAgregado 4s ease-in-out;
    z-index: -1;

    &Text, &Icon {
        font-family: "Roboto", sans-serif;
        margin: 5px;
    }
}

@keyframes mostrarAgregado {
    0% {transform: translateY(0);}

    25%{transform: translateY(50px);}
    35%{transform: translateY(45px);}
    45%{transform: translateY(50px);}
    55%{transform: translateY(45px);}
    65%{transform: translateY(50px);}

    100%{transform: translateY(0);}
}