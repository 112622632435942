.card-content-init {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 25px 0;
}

.card-content-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1450px;
    margin: 30px auto;
    padding: 10px;
}

.content-logoBox {
    display: flex;
    flex-direction: column;
    align-items: center;

    &Img {
        width: 500px;
        height: 375px;
        object-fit: cover;
        @media(max-width: 768px) {
            width: 400px;
            height: 275px;
        }
        @media(max-width: 650px) {
            width: 300px;
            height: 200px;
        }
    }
}

.content-mencion {
    display: flex;
    align-items: center;
    width: 200px;
    height: 200px;
    background-image: url("../../../public/img/pexels-fwstudio-122458.jpg");
    padding: 10px;
    border-radius: 100px;
    box-shadow: -5px 5px 10px #d9d9d9, 5px -5px 10px #e7e7e7;


    &BoxDesc {
        width: 160px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    &BoxIcon {
        height: 50px;
        width: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
    }

    &Des {
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        text-shadow: -2px 2px 5px white;
        
    }

    &Icon {
        width: 100%;
        height: 100%;
    }
}

.content-boxRow {
    display: flex;
    align-items: center;
    @media(max-width: 1200px) {
        flex-direction: column;
    }
}

.content-mencionBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    text-align: center;
}

.content-mencionBoxTitle {
    font-family: 'Playfair Display', serif;
    font-size: 1.5rem;
    color: #dda123;
    text-shadow: -.5px .5px 0px black;
    @media(max-width: 650px) {
        font-size: 1.2rem;
    }

    &--modify {
        color: #87212e;
        text-shadow: none;
        font-weight: bold;
    }
}

.content-ajuste {
    height: 200px;
}

.card-contentTitle {
    font-family: 'Roboto', sans-serif;
    font-size: 3rem;
    margin-top: 1.5rem;
}