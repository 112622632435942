.aside {
    width: 90%;
    height: 150px;
    background-color: #121212;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(24px);
    transition: all 1s ease;

    &-item{
        margin: 10px;
    }
}