.nav {
    position: sticky;
    top: 0;
    z-index: 4;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 1em 0.25em #121212, 0 0 rgb(0 0 0), 0 0 0.75em 0.25em rgb(255 255 255);
    background-color: #121212;

    &-content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        z-index: 3;
        @media(max-width: 992px) {
            justify-content: space-around;
        }

    }

    &-bandera {
        width: 90px;
        height: 100%;
        position: absolute;
        left: 0;
        overflow: hidden;
        z-index: 2;

        &Content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            transform: rotate(299deg);
        }
    }

    &-lineaRoja{
        background-color: #cd0d2d;
        width: 200px;
        height: calc(30% / 3);
    }
    
    &-lineaAmarilla {
        background-color: #fecb00;
        width: 200px;
        height: calc(30% / 3);
    }

    &-notif {
        display: flex;
        justify-content: center;
        height: 40px;
        position: absolute;
        top: 62%;
        width: 100%;
        background-color: #121212;
    }

    &-logo {
        display: flex;
        align-items: center;
       
    }

    &-img {
        width: 100px;
    }

    &-text {
        font-family: 'Copperplate', sans-serif;;
        letter-spacing: 1px;
        margin: 5px;
        font-size: 1.3rem;
        color: white;
        @media(max-width: 768px) {
            font-size: 1.1rem;
        }
    }
    
    &-enlace {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;//OJO, QUIZAS SE SAQUE
        @media(max-width: 992px) {
            display: none;
        }
    }
    
    &-item {
        text-transform: uppercase;
        color: white;
        text-decoration: none;
        font-family: 'Roboto', sans-serif;
        transition: all .2s ease-in;
        margin:  0 36px;

        &:hover {
            color: #b0976d;
            transition: all .2s ease-in;
        }
    }

    &-flex {
        display: flex;
        align-items: center;
    }

    &-burger {
        display: flex;
        align-items: center;
        @media (min-width: 993px) {
            display: none;
        }
    }
}
