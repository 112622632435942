.main {
    max-width: 1300px;
    margin: 30px auto;
    padding: 10px;
    text-align: center;

    &-title {
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 2.5rem;
        text-align: center;
        @media(max-width: 768px) {
            font-size: 2rem;
        }
        @media(max-width: 650px) {
            font-size: 1.6rem;
        }
    }
}