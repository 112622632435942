* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

#root {
    background-color: #ffffff;
}

.disabled {
    pointer-events: none;
    color: #999 !important; 
    cursor: not-allowed; 
}