.cart-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    @media(max-width: 1200px) {
        justify-content: center;
    }
}

.cart-content-item {
    min-width: 65%;
    margin: 1rem;
    @media(max-width: 1200px) {
        width: 90%;
        height: 100%;
    }
    @media(max-width: 650px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.cart-content-total {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5%;
    background-color: #dbcb92;
    width: 320px;
    min-height: 275px;
    margin: 1rem;
}

.cart-total {
    width: 100%;
    text-align: center;
    background-color: #b19129;
}

.cart-total-btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 80px;
}

.btn-link {
    text-decoration: none;
}

.cart-empty {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;

    &Text {
        font-family: "Roboto", sans-serif;
        font-size: 1.2rem;
        margin: 1rem;
    }
}

.cart-totalTitle {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
}

.cart-totalPrice {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    color: white;
}