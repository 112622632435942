.slider {
    overflow: hidden;
    height: 90vh;
    @media(max-width: 768px) {
        height: 85vh;
    }
    @media(max-width: 650px) {
        height: 50vh;
    }
    @media(max-width: 450px) {
        height: auto;
    }

    &_container {
        padding: 0 !important
    }
    
    &-box {
        display: flex;
        width: 400%;
        height: 100%;
    }

    &-content {
        width: calc(100% / 4);
    }
    
    &-img {
        width: 100%;
        height: 100%;
        animation: movimiento 20s infinite;
    }

    &-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        
    }
    
    &-text {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: flex-start;
        justify-content: center;
        position: absolute;
        top: 0;
        margin-left: 2rem;
        text-shadow: -2px 2px 0 black;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;

        &.active {
            opacity: 1;
            transform: translateY(0);
        }

        
        @media(max-width: 650px) {
            margin-left: 1rem;
        }
    }
}

.content-textUno {
    color: white;
    font-size: 3rem;
    font-family: "Roboto", sans-serif;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    @media(max-width: 992px) {
        font-size: 2.5rem;
    }
    @media(max-width: 768px) {
        font-size: 2rem;
        height: 2rem;
    }
    @media(max-width: 768px) {
        font-size: 1.2rem;
        height: 1.2rem;
    }
}

.content-textDos {
    color: white;
    font-size: 6rem;
    font-family: "Playfair Display", serif;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
    @media(max-width: 992px) {
        font-size: 4.5rem;
    }
    @media(max-width: 768px) {
        font-size: 4rem;
        height: 4rem;
    }
    @media(max-width: 768px) {
        font-size: 2rem;
        height: 2rem;
    }
}

.content-textEffect {
    opacity: 0;
    transition: all .5s ease;
    transform: translateY(55px);
    animation: mostrar 5s infinite;
    text-shadow: -2px 2px 0 black;
}

.delay {
    opacity: 0;
    transition: all .5s ease;
    transform: translateY(55px);
    animation: mostrarDelay 5s infinite;
}

//  @keyframes mostrar {
//      0%{
//          opacity: 0;
//          transition: all .5s ease;
//          transform: translateY(55px);
//      }
//      15%{
//          opacity: 1;
//          transition: all .5s ease;
//          transform: translateY(0);
//      }
//      25%{
//          opacity: 1;
//          transition: all .5s ease;
//          transform: translateY(0);
//      }
//      50%{
//          opacity: 1;
//          transition: all .5s ease;
//          transform: translateY(0);
//      }
//      65%{
//          opacity: 0;
//          transition: all .5s ease;
//          transform: translateY(55px);
//      }
//      100%{
//          opacity: 0;
//          transition: all .5s ease;
//          transform: translateY(55px);
//      }

//  }

//  @keyframes mostrarDelay {
//      0%{
//          opacity: 0;
//          transition: all .5s ease;
//          transform: translateY(55px);
//      }
//      20%{
//          opacity: 1;
//          transition: all .5s ease;
//          transform: translateY(0);
//      }
//      30%{
//          opacity: 1;
//          transition: all .5s ease;
//          transform: translateY(0);
//      }
//      55%{
//          opacity: 1;
//          transition: all .5s ease;
//          transform: translateY(0);
//      }
//      70%{
//          opacity: 0;
//          transition: all .5s ease;
//          transform: translateY(55px);
//      }
//      100%{
//          opacity: 0;
//          transition: all .5s ease;
//          transform: translateY(55px);
//      }

//  }

//  @keyframes movimiento {
//     0%{transform: translateX(0);}
//     20%{transform: translateX(0);}  //4 seg = 20% - 1 seg = 5% siguiendo el ej de animation "20s"

//     25%{transform: translateX(-100%);}
//     45%{transform: translateX(-100%);}

//     50%{transform: translateX(-200%);}
//     70%{transform: translateX(-200%);}

//     75%{transform: translateX(-300%);}
//     95%{transform: translateX(-300%);}
//     100%{transform: translateX(0%);}
//  }
