.detalle-envio {
    display: flex;
    margin: 10px;
    @media(max-width: 992px) {
        flex-direction: column;
        align-items: center;
    }
}

.detalle-envioBox {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
    overflow: hidden;
    margin: 10px;
    @media(max-width: 992px) {
        width: 85%;
    }
}

.detalle-envioImg {
    width: 95%;
    height: 100%;
    @media(max-width: 992px) {
        width: 85%;
        height: 90%;
    }
}

.detalle-envioTitle {
    font-family: "Roboto", sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    @media(max-width: 768px) {
        font-size: 2rem;
    }
    @media(max-width: 650px) {
        font-size: 1.6rem;
    }
}

.detalle-envioDesc {
    font-family: "Playfair Display", serif;
    font-size: 1.3rem;
    font-style: italic;
    letter-spacing: 0.5px;
    line-height: 2rem;
    @media(max-width: 768px) {
        font-size: 1rem;
        margin: 1rem 0 0 0;
    }
}

.detalle-envioBtn {
    margin: 4rem 2rem;
}