.content-flyer {
    height: 450px;
    display: flex;
    width: 100%;
    margin: 2rem 0;

    @media(max-width: 650px) {
        height: 390px;
    }
    
    &Box {
        width: 50%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }
    
    &Text {
        font-family: 'Roboto', sans-serif;
        font-size: 2rem;
        width: 50%;
        margin: 1rem;
        font-weight: 400;
        text-shadow: -2px 2px 10px black;
        color: white;
        @media(max-width: 1200px) {
            width: 75%;
            text-align: center;
        }
        @media(max-width: 768px) {
            font-size: 1.6rem;
        }
        @media(max-width: 650px) {
            font-size: 1.2rem;
        }
    }
    
    &Img {
        height: 165%;
        transform: rotate(45deg);
        @media(max-width: 1200px) {
            transform: rotate(35deg);
            position: relative;
            left: 20px;
        }
        @media(max-width: 992px) {
            height: 135%;
        }
        @media(max-width: 768px) {
            height: 120%;
        }
        @media(max-width: 650px) {
            transform: rotate(25deg);
        }
        @media(max-width: 450px) {
            transform: rotate(15deg);
        }
    }

    &Image {
        position: absolute;
        width: 100%;
        height: inherit;
        object-fit: cover;
    }
}
