.wpp-content {
    border: none;
    background-color: transparent;
    position: sticky;
    bottom: 10px;
    left: 95%;
    z-index: 1;
    @media(max-width: 1200px) {
        left: 94%;
    }
    @media(max-width: 992px) {
        left: 93%;
    }
    @media(max-width: 768px) {
        left: 92%;
    }
    @media(max-width: 650px) {
        left: 90%;
    }
    @media(max-width: 450px) {
        left: 85%;
    }
}

.wpp-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0px;
    border-radius: 50%;
    background-color: #1ad03f;
    width: 50px;
    height: 50px;
    transition: all 1s;
    cursor: pointer;

    &:hover {
        transform: scale(1.1)
    }

}

.wpp-icon {
    color: white;
    width: 30px;
    height: 30px;

}