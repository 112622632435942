.cart__widget {
    display: flex;
    font-size: 25px;
    color: white;
    text-decoration: none;
    margin-left: 5px;
}

.cart__hidden {
    opacity: 0;
    visibility: hidden;
}

.cart__count {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    transition: all .5s ease-out;
}